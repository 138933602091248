import * as React from 'react'
import update from 'immutability-helper'
import { EditableMultiSelect, NewCheckbox, ActionTarget, AutocompleteInput, Item, ActionCondition, EVENT_NAMES, VALIDATION_TYPES, newTheme, Input, Collapse, NewMultiSelect, getFlowElement, ActionContext, ACTION_EVENTS, FIRST_STEP_NAMES, ACTION_TARGET_TYPES, ACTION_TARGET_NAMES, Select, NewButton, NewDatePicker, ACTIONS, Reminder, NotificationType, ROLE_NAME, ROLES, ADMIN_PORTAL_ROLES } from 'prace-common-components'
import { $ActionsContainer, $ModalContainer, $ElementContainer, $SvgIcon, $NotifySupportContainer, $KeepUserRolesContainer } from './styles'
import { ActionsModalProps, EditTargetType, EditAction, EditActionCondition } from './types'
import Grid from '@material-ui/core/Grid'
import useElementSelects from 'util/useElementSelects'
import { FormElement } from 'components/FormModal/types'
import { $Grid } from 'components/EditCallModal/styles'
import { ElementIds, getElementDetails } from 'util/getElementDetails'
import { RoleNameItems, Routes } from 'constants/global'
import { Template } from 'store/api/templates/types'
import { useAppDispatch } from 'store/hooks'

const FilteredRoleNameItems = RoleNameItems.filter((item) => ![ROLES.USER, ROLES.SUPER_ADMIN].includes(item.value))

const targetTypes = Object.values(ACTION_TARGET_TYPES).map(
  (type) => ({ label: ACTION_TARGET_NAMES[type], value: type }),
)

const leadTargetTypes = Object.values(ACTION_TARGET_TYPES).map(
  (type) => ({
    label: [ACTION_TARGET_NAMES.non_lead_assignment_owner,
      ACTION_TARGET_NAMES.assignment_owners,
      ACTION_TARGET_NAMES.approved_assignments].includes(ACTION_TARGET_NAMES[type]) ?
      `First ${ACTION_TARGET_NAMES[type].slice(0,-1).replace('All ', '')}`
      :
      ACTION_TARGET_NAMES[type],
    value: type,
  }),
).filter((type) => type.value !== ACTION_TARGET_TYPES.PROS)

const conditionTypes = Object.values(VALIDATION_TYPES).filter(
  (type) => type !== VALIDATION_TYPES.UNCONDITIONAL && type !== VALIDATION_TYPES.LENGTH_RANGE)
  .map((c) => ({ value: c, label: c }))

//TODO: Dummy Reminder
const repeatOptions = [
  { label: '1 month before', value: 'month' },
]

const emptyFlowElement = { id: null, step: null, form: null, element: null, groupElement: null }
const emptyTarget = { order: 0, targetType: null, flowElement: emptyFlowElement }
const emptyReminder = {
  start: undefined,
  end: undefined,
  repeat: undefined,
}
const emptyAction =  {
  event: undefined,
  targets: [emptyTarget],
  context: {
    id: 0,
    flowElement: emptyFlowElement,
    notificationId: null,
    notification: { id: null },
    notifySupport: true,
    makeLead: false,
    keepUserRoles: true,
    notifyEmails: [],
    onlySubmitted: true,
  },
  condition: {
    conditionalElement: emptyFlowElement,
    values: [null],
    type: null,
  },
  every: false,
  lead: false,
  roles: [],
  reminder: emptyReminder, //TODO: Handle reminder when BE is ready
}

const selectStepTargets = [
  ACTION_TARGET_TYPES.LEAD_ASSIGNMENT_OWNER,
  ACTION_TARGET_TYPES.NON_LEAD_ASSIGNMENT_OWNER,
  ACTION_TARGET_TYPES.ALL_ASSIGNMENT_OWNERS,
  ACTION_TARGET_TYPES.APPROVED_ASSIGNMENTS,
]
const selectElementTargets = [ACTION_TARGET_TYPES.FLOW_ELEMENT_EMAIL, ACTION_TARGET_TYPES.PARTITION_CCR]

/* const ownerStepTargets = [
  ACTION_TARGET_TYPES.ASSIGNMENT_OWNER,
  ACTION_TARGET_TYPES.LEAD_ASSIGNMENT_OWNER,
  ACTION_TARGET_TYPES.NON_LEAD_ASSIGNMENT_OWNER,
  ACTION_TARGET_TYPES.ALL_ASSIGNMENT_OWNERS,
  ACTION_TARGET_TYPES.APPROVED_ASSIGNMENTS,
] */

export const ActionsModal: React.FC<ActionsModalProps> = (
  { call, templates, step, adminCheck = false, type, action = emptyAction, onAddAction, targetingAssignActions },
) => {
  const dispatch = useAppDispatch()
  const [templateOptions, setTemplateOptions] = React.useState<Item[]>(
    templates.filter((template) => template.callId == call.id)
      .map((template: Template) => ({ label: template.title, value: template.id })))
  const [modalStep, setModalStep] = React.useState<number>(0)
  const [emailAssignee, setEmailAssignee] = React.useState<number | undefined>(undefined)
  const [showGroupElement, setShowGroupElement] = React.useState<boolean>(false)
  const actionConditionalElement = action.condition?.conditionalElement ?
    action.condition?.conditionalElement : emptyFlowElement
  const conditionElementId = actionConditionalElement?.id
  const conditionElement = conditionElementId ?
    getFlowElement(call, Number(conditionElementId)) : undefined
  //TODO: Handle reminder when BE is ready
  const [reminder, setReminder] = React.useState<boolean>(false)

  const canHaveLeads = step.config?.hasLead
  
  let actionEvent = action.event
  if(action?.lead != null) {
    if(action?.event === ACTION_EVENTS.SUBMITTED) {
      actionEvent = action?.lead === true ? ACTION_EVENTS.LEAD_ASSIGNMENT_SUBMITTED
        : action?.lead === false ? ACTION_EVENTS.NON_LEAD_ASSIGNMENT_SUBMITTED
          : action.event
    }

    else if(action?.event === ACTION_EVENTS.ASSIGNED) {
      actionEvent = action?.lead === true ? ACTION_EVENTS.LEAD_ASSIGNED
        : action?.lead === false ? ACTION_EVENTS.NON_LEAD_ASSIGNED
          : action.event
    }
    else actionEvent = action.event
  }
  else if(action?.every) actionEvent = ACTION_EVENTS.ALL_SUBMITTED

  const [modalState, setModalState] = React.useState<EditAction>({
    ...action,
    context: {
      ...action.context,
      notification: {
        id: action.context.notification?.id || action.context.notificationId,
      },
    },
    condition: {
      ...action.condition,
      conditionalElement: conditionElementId ? {
        step: conditionElement?.step?.id || null,
        form: conditionElement?.form?.id || null,
        element: conditionElement?.element?.id || null,
      } : actionConditionalElement,
      values: action.condition?.values ? (action.condition?.values as string[])[0] : null,
    } as EditActionCondition,
    event: actionEvent,
  })
  const openedEmail = emailAssignee !== undefined
  const isVisibility = type === ACTIONS.VISIBILITY
  const isNotify = type === ACTIONS.NOTIFY
  const isAssign = type === ACTIONS.ASSIGN
  const { event, targets, context, condition, roles = [] } = modalState
  const { order } = step
  const [open, setOpen] = React.useState<boolean>(!!roles.length)
  const [openCondition, setOpenCondition] = React.useState<boolean>(!!condition?.type)

  /* Target Step is the one selected in the first part of the modal */
  //const targetStep = call?.flow.find((step) => step.id === modalState.context?.flowElement?.step)
  /* const targetAssignees = targetStep?.permissions?.assignable?.filter((role) => 
  !ADMIN_PORTAL_ROLES.includes(role))|| [] */
  /* Origin step is the current one */
  //const originAssignees = step?.permissions?.assignable?.filter((role) => !ADMIN_PORTAL_ROLES.includes(role)) || []
  //const originAssigners = step?.permissions?.assign?.filter((role) => !ADMIN_PORTAL_ROLES.includes(role)) || []

  const selectedTargetTypes = targets.map((t) => t.targetType)
  const flowElementEmailTargeted = selectedTargetTypes.includes(ACTION_TARGET_TYPES.FLOW_ELEMENT_EMAIL)
  const proTargeted = selectedTargetTypes.includes(ACTION_TARGET_TYPES.PROS)
  const partitionCCRTargeted = selectedTargetTypes.includes(ACTION_TARGET_TYPES.PARTITION_CCR)
  const applicationOwnerTargeted = selectedTargetTypes.includes(ACTION_TARGET_TYPES.APPLICATION_OWNER)

  React.useEffect(() => {
    setTemplateOptions(
      templates.filter((template) => template.callId == call.id)
        .map((template: Template) => ({ label: template.title, value: template.id })))
  }, [call.id, templates])

  const events = React.useMemo(() => {
    if(order) {
      const otherStepEvents = Object.values(ACTION_EVENTS).map((evt) => ({ label: EVENT_NAMES[evt], value: evt }))
        .filter((action) => canHaveLeads ? true :
          ![ACTION_EVENTS.LEAD_ASSIGNED, ACTION_EVENTS.LEAD_ASSIGNMENT_SUBMITTED].includes(action.value))
      if(adminCheck) return otherStepEvents.filter((action) => (!!action.label))
      else return otherStepEvents.filter((action) => (!!action.label && action.value !== ACTION_EVENTS.APPROVED))
    } else { // First Step
      const firstStepEvents = Object.values(ACTION_EVENTS).map((evt) => ({ label: FIRST_STEP_NAMES[evt], value: evt }))
        .filter((action) => canHaveLeads ? true :
          ![ACTION_EVENTS.LEAD_ASSIGNED, ACTION_EVENTS.LEAD_ASSIGNMENT_SUBMITTED].includes(action.value))
      if(adminCheck) return firstStepEvents.filter((action) => (!!action.label))
      else return firstStepEvents.filter((action) => (!!action.label &&
        action.value !== ACTION_EVENTS.DRAFT && action.value !== ACTION_EVENTS.APPROVED))
    }
  }, [order, adminCheck, canHaveLeads])

  const isDisabled: () => boolean = () => {
    if(openedEmail) {
      const currentAssignee = { ...targets![emailAssignee], targetElementId: undefined }
      if(selectStepTargets.includes(currentAssignee.targetType! as ACTION_TARGET_TYPES)) {
        /* Only step is needed to be selected */
        return Object.values(currentAssignee).includes(null) || currentAssignee.flowElement.step === null
      } else {
        /* Only step, form and element are needed to be selected */
        return Object.values(currentAssignee).includes(null) ||
          (showGroupElement ? currentAssignee.flowElement.groupElement === null :
            currentAssignee.flowElement.element === null)
      }
    } else switch(modalStep) {
      case 0:
        return !event
      case 1:
        return (!isNotify && !isAssign && context.flowElement.step === null) ||
        (isAssign && context.flowElement.step === null) ||
        (isNotify && !reminder && context.notificationId === null)
      default:
        return targets!.find((assignee: EditTargetType) => assignee.targetType === null) !== undefined
        //TODO: Uncomment when reminders ready
        //|| (reminder && Object.values(modalState.reminder!).includes(undefined))
    }
  }

  //TODO: Future IMPROVEMENT: Only show elements that are of type EMAIL when selecting flow element email
  const [_, elementSelects] = useElementSelects(
    call?.flow,
    modalStep === 0 && condition ?
      {...condition.conditionalElement, step: step.id, groupElement: null}
      : modalStep === 1 && !isNotify ?
        context.flowElement
        : !openedEmail ? emptyFlowElement : targets![emailAssignee].flowElement,
    openedEmail ? 'What flow element will have the email of the assigned user?' : 'What flow element will be assigned to the target?',
    (openedEmail && selectStepTargets.includes(targets![emailAssignee].targetType! as ACTION_TARGET_TYPES)) ||
      (modalStep === 1 && (isAssign || type === ACTIONS.VISIBILITY)),
    false,
    showGroupElement,
  )

  const onChange = (name: string, val: Nullable<StringNumber | EditTargetType[] | string[] | Reminder | boolean>) => {
    if(name === 'notifySupport' ||  name === 'onlySubmitted' ||  name === 'notifyEmails' || name === 'makeLead' || name === 'keepUserRoles') {
      setModalState((prevState) => update(prevState, {
        roles: { $set: (name === 'keepUserRoles') ? [] : prevState.roles },
        context: {
          [name]: { $set: val as boolean },
        }}))
    } else {
      if(name === 'notification') {
        setModalState((prevState) => update(prevState, {
          context: {
            notification: {
              id: { $set: val as number },
            },
            notificationId: { $set: val as number },
          }}))
      } else {
        setModalState((prevState) => update(prevState, {
          [name]: { $set: val },
        }))
      }
    }
  }

  const onInputChange = (_: any, value: string) => {
    if(value) {
      setTemplateOptions(
        templates.filter((template: Template) =>
          template.callId == call.id &&
          (template.title.toLocaleLowerCase().includes(value.toLocaleLowerCase()) || template.tags.includes(value)))
          .map((template: Template) => ({ label: template.title, value: template.id })))
    } else {
      onChange('notification', null) // Clear input
      setTemplateOptions(templates.filter((template) => template.callId == call.id)
        .map((template: Template) => ({ label: template.title, value: template.id })))
    }
  }

  const onChangeFlowElement = (name: string, val: Nullable<StringNumber>) => {
    setModalState((prevState) => {
      /* Prevent infinite action loop if user selects same step */
      if(isAssign && prevState.event &&
        [ACTION_EVENTS.ASSIGNED, ACTION_EVENTS.LEAD_ASSIGNED, ACTION_EVENTS.NON_LEAD_ASSIGNED].includes(prevState.event)
        && step.id == val) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'The target step cannot be the same as the triggering step.' } })
        return prevState
      }
      /* Prevent infinite action loop if user selects 
      a step that has assign actions targeting this step */
      if(isAssign && prevState.event &&
        targetingAssignActions.find((action) => action.parentId == val)
      ) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'The target step has assign automations targetting the current step' } })
        return prevState
      }
      return update(prevState, {
        context: {
          flowElement: {
            [name]: { $set: val },
          },
        }})
    })
  }

  const onChangeCondition = (name: string, val: Nullable<StringNumber>) => {
    setModalState((prevState) => {
      return update(prevState, {
        condition: {
          [name]: { $set: val },
        }})
    })
  }

  const onChangeConditionElement = (name: string, val: Nullable<StringNumber>) => {
    setModalState((prevState) => {
      return update(prevState, {
        condition: {
          conditionalElement: {
            [name]: { $set: val },
          },
        }})
    })
  }

  const onChangeReminder = (name: string, val: Nullable<StringNumber>) => {
    setModalState((prevState) => update(prevState, {
      reminder: {
        [name]: { $set: val },
      },
    }))
  }

  const deleteReminder = () => {
    onChange('reminder', emptyReminder)
    setReminder(false)
  }

  const onChangeAssigneeElement = (name: string, value: Nullable<string>) => {
    if(name === 'element') {
      const flowElement = getFlowElement(call, Number(value))
      const element = flowElement.element
      /* Group element selected */
      /* Show select for elements inside group */
      setShowGroupElement(!!(element && element.flow.length))
    }
    const newAssignees = update(targets! as EditTargetType[], {
      [emailAssignee || 0]: {
        flowElement: {
          [name]: { $set: value },
        }}})
    onChange('targets', newAssignees)
  }

  const onChangeAssign = (value: Nullable<string | ACTION_TARGET_TYPES>, idx: number) => {
    /* Check if the roles of both steps are compatible when selecting owner targets */
    /* FIXME: ADD THIS CHECK BUT BETTER */
    /* if(isAssign && ownerStepTargets.includes(value as ACTION_TARGET_TYPES)) {
      if(!originAssignees.filter((role) => targetAssignees.includes(role)).length) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 
        'Target step assignees are not compatible.' } })
        return
      }
    } */

    /* FIXME: ADD THIS CHECK BUT BETTER */
    /* Check if the roles of both steps are compatible when selecting assigner targets */
    /* if(isAssign && ((value as ACTION_TARGET_TYPES) === ACTION_TARGET_TYPES.ASSIGNER)) {
      if(!originAssigners.filter((role) => targetAssignees.includes(role)).length) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 
        'Target step assignees are not compatible.' } })
        return
      }
    } */

    if(value && (selectElementTargets.includes(value as ACTION_TARGET_TYPES)
      || selectStepTargets.includes(value as ACTION_TARGET_TYPES)
    ))
      setEmailAssignee(idx)
    const newAssignees = update(targets! as EditTargetType[], {
      [idx]: {
        targetType: { $set: value as ACTION_TARGET_TYPES },
        flowElement: { $set: emptyFlowElement },
      }})
    onChange('targets', newAssignees)
  }

  const addAssign = () => {
    setModalState((prevState) => update(prevState, {
      targets: { $push: [{ order: targets.length + 1, targetType: null, flowElement: emptyFlowElement }] },
    }))
  }

  const deleteAssign = (idx: number) => {
    if(idx === 0) {
      setModalState((prevState) => {
        return prevState.targets.length > 1 ?
          update(prevState, {
            targets: { $splice: [[idx, 1]] }})
          : 
          update(prevState, {
            targets: { $splice: [
              [idx, 1],
              [idx, 0, emptyTarget],
            ] },
          })
      })
    } else {
      setModalState((prevState) => update(prevState, {
        targets: { $splice: [[idx, 1]] },
      }))
    }
  }

  const handleBack = (openedEmail: boolean) => {
    if(reminder) {
      setReminder(false)
      if(isDisabled()) /* If the user did not complete reminder */
        deleteReminder()
    } else {
      if(openedEmail) {
        setEmailAssignee(undefined)
        /* Deletes last assign */
        deleteAssign(modalState.targets.length-1)
      } else {
        setModalStep(modalStep - 1)
      }
    }
  }

  const handleNext = () => {
    if(reminder) {
      setReminder(false)
      return
    }
    if(openedEmail) {
      setEmailAssignee(undefined)
    } else {
      if(modalStep === 2) {
        const { context, reminder, targets, condition, event, roles, ...rest } = modalState
        const {
          flowElement,
          notification,
          notificationId,
          notifySupport = false,
          notifyEmails = [],
          onlySubmitted = true,
          makeLead = false,
        } = context

        if(isAssign && flowElementEmailTargeted && (!roles.length || ADMIN_PORTAL_ROLES.includes(roles[0] as ROLES))) {
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Assign role is required.' } })
          return
        }
        /* Prevent infinite action loop if user selects same step */
        if(isAssign && event &&
        [ACTION_EVENTS.ASSIGNED, ACTION_EVENTS.LEAD_ASSIGNED, ACTION_EVENTS.NON_LEAD_ASSIGNED].includes(event)
        && step.id == flowElement.step) {
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'The target step cannot be the same as the triggering step.' } })
          return
        }
        /* Prevent infinite action loop if user selects 
      a step that has assign actions targeting this step */
        if(isAssign && event &&
          targetingAssignActions.find((action) => action.parentId == flowElement.step)
        ) {
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'The target step has assign automations targetting the current step' } })
          return
        }

        let id: number = flowElement.step! as number
        /* Visibility can have step, form or element selected (form and element optional) */
        if(!isAssign && !isNotify && flowElement.form) id = flowElement.form! as number
        if(isNotify || (!isAssign && flowElement.element)) id = flowElement.element! as number

        /* FIXME: Make this fix better */
        if(isAssign && flowElementEmailTargeted && proTargeted) {
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msdsadasg: 'You can target both PROs and flow element emails in the same assign automation' } })
          return
        }

        targets.map((t) => t.targetType).includes(ACTION_TARGET_TYPES.FLOW_ELEMENT_EMAIL)

        const newContext = {
          id: context.id,
          notification,
          notificationId,
          notifySupport,
          notifyEmails,
          onlySubmitted,
          makeLead,
          keepUserRoles: isAssign ? !flowElementEmailTargeted : false,
          flowElement: { id },
        }
        let newCondition: ActionCondition | undefined = undefined
        if(condition && condition.type && condition.conditionalElement?.element) newCondition = {
          type: condition.type,
          values: [condition.values as string || ''],
          conditionalElement: { id: condition!.conditionalElement.element! },
        }
        const newTargets: ActionTarget[] = targets.map((target) => {
          const { targetType, flowElement } = target
          return {
            id: target.id as number,
            targetType: targetType! as ACTION_TARGET_TYPES,
            targetElement:
              {id: selectStepTargets.includes(targetType! as ACTION_TARGET_TYPES) ? flowElement.step! as number :
                flowElement.groupElement ? flowElement.groupElement! as number : flowElement.element! as number},
          }
        })
        const every = event === ACTION_EVENTS.ALL_SUBMITTED
        const lead = event === ACTION_EVENTS.LEAD_ASSIGNMENT_SUBMITTED || event === ACTION_EVENTS.LEAD_ASSIGNED ? true
          : event === ACTION_EVENTS.NON_LEAD_ASSIGNMENT_SUBMITTED || event === ACTION_EVENTS.NON_LEAD_ASSIGNED ? false
            : null

        let eventTrigger = event
        if([
          ACTION_EVENTS.LEAD_ASSIGNMENT_SUBMITTED,
          ACTION_EVENTS.NON_LEAD_ASSIGNMENT_SUBMITTED,
          ACTION_EVENTS.ALL_SUBMITTED,
        ].includes(event!)) {
          eventTrigger = ACTION_EVENTS.SUBMITTED
        }
        else if([
          ACTION_EVENTS.LEAD_ASSIGNED,
          ACTION_EVENTS.NON_LEAD_ASSIGNED,
        ].includes(event!)) {
          eventTrigger = ACTION_EVENTS.ASSIGNED
        }

        const newRoles = isAssign ?
          applicationOwnerTargeted ? [ROLES.APPLICANT] :
            partitionCCRTargeted ? [ROLES.COMPUTE_CENTRE_REPRESENTATIVE] :
              proTargeted ? [] : roles
          : roles

        //TODO: Add reminder for Notify when BE is ready
        onAddAction(type, {
          ...rest,
          roles: newRoles,
          event: eventTrigger! as ACTION_EVENTS,
          context: newContext as ActionContext[ACTIONS],
          condition: newCondition,
          targets: newTargets,
          every,
          lead,
        })
      } else {
        setModalStep(modalStep + 1)
      }
    }
  }

  const flowElementSelector = (elementSelects as FormElement[]).map((select: FormElement) =>
    <$ElementContainer container key={select.id}>
      <Select
        disabled={modalStep === 0 && select.name === 'step'}
        name={select.name}
        value={select.value as string}
        items={select.items || []}
        {...select.options}
        onChange={
          openedEmail ? onChangeAssigneeElement : modalStep === 0 ? onChangeConditionElement : onChangeFlowElement
        }
      />
    </$ElementContainer>,
  )

  const targetLabel = (type: ACTIONS) => {
    switch(type) {
      case ACTIONS.ASSIGN:
        return 'Who do you want to assign?'
      case ACTIONS.VISIBILITY:
        return 'Who do you want to show the flow element to?'
      default: // case ACTIONS.NOTIFY
        return 'Who do you want to notify?'
    }
  }

  const getElementText = (flowElement: ElementIds) => {
    const { step, form, element, groupElement } = getElementDetails(call, flowElement)
    return groupElement ? `${form.title} > ${element.title} > ${groupElement.title}`
      : element ? `${step.title} > ${form.title} > ${element.title}`
        :  step ?  `${step.title}` : undefined
  }

  const onEditTemplate = (templateId: StringNumber) => {
    window.open(Routes.TEMPLATE(templateId), '_blank')
  }

  const targetStepIndex = context.flowElement.step ? 
    call?.flow.findIndex((step) => step.id === context.flowElement.step) : -1
  const assignable = targetStepIndex > -1 ? call?.flow?.[targetStepIndex]?.permissions?.assignable || [] : []
  const assignableRoles: Item[] = assignable.filter((r) => !ADMIN_PORTAL_ROLES.includes(r))
    .map((role) => ({ label: ROLE_NAME[role], value: role }))

  let possibleTargets = (context.makeLead ?
    leadTargetTypes
    : targetTypes.filter(
      /* For Step Unlock, do not allow Triggering Assignment Owner and Triggering Assignment Assigner */
      (target) => event == ACTION_EVENTS.UNLOCKED ?
        ![ACTION_TARGET_TYPES.ASSIGNMENT_OWNER, ACTION_TARGET_TYPES.ASSIGNER].includes(target.value)
        : true,
    ))

  if(isAssign) {
    /* Remove application owner target if not already there */
    if(!assignable.includes(ROLES.APPLICANT) 
      && !targets.map((t) => t.targetType).includes(ACTION_TARGET_TYPES.APPLICATION_OWNER))
      possibleTargets = possibleTargets.filter(
        (target) => target.value !== ACTION_TARGET_TYPES.APPLICATION_OWNER)
    /* Remove CCR target if not already there */
    if(!assignable.includes(ROLES.COMPUTE_CENTRE_REPRESENTATIVE) 
      && !targets.map((t) => t.targetType).includes(ACTION_TARGET_TYPES.PARTITION_CCR))
      possibleTargets = possibleTargets.filter(
        (target) => target.value !== ACTION_TARGET_TYPES.PARTITION_CCR)
  } else { /* Do not allow Admins from being targeted by notify and visibility */
    possibleTargets = possibleTargets.filter(
      (target) => target.value !== ACTION_TARGET_TYPES.PROS)
  }

  const content = (openedEmail: boolean, reminder: boolean, event?: ACTION_EVENTS) => {
    if(openedEmail) {
      return <Grid container>
        {flowElementSelector}
      </Grid>
    } else if(reminder) {
      return <$Grid container>
        <NewDatePicker title='Start Date' name='start' value={modalState.reminder?.start} onChange={onChangeReminder} />
        <NewDatePicker title='End Date' name='end' value={modalState.reminder?.end} onChange={onChangeReminder} />
        <Select title='Repeat Reminder?' name='repeat' items={repeatOptions} value={modalState.reminder?.repeat} onChange={onChangeReminder} />
      </$Grid>
    } else switch(modalStep) {
      case 2:
        return <>
          {targets!.map((assignee: EditTargetType, idx: number) => {
            const description = getElementText(assignee.flowElement)
            return <$ElementContainer key={`${idx}-${assignee.id || assignee.order}`} container alignItems={description && idx > 0 ? 'flex-start' : 'center'} justifyContent='space-between'>
              <Grid item xs={11}>
                <Select
                  name='targets'
                  value={assignee.targetType}
                  items={possibleTargets}
                  title={idx === 0 ? targetLabel(type) : undefined}
                  placeholder='Select target user for the action'
                  description={description}
                  onChange={(_name: string, value: Nullable<string>) => onChangeAssign(value, idx)}
                />
              </Grid>
              <Grid item>
                <$SvgIcon name='delete' size={20} color='#757778' clickable onClick={() => deleteAssign(idx)}/>
              </Grid>
            </$ElementContainer>
          },
          )}
          <NewButton variant='outlined' disabled={context.makeLead} creator onClick={() => addAssign()}>Add Target</NewButton>
          {isAssign &&
            <$NotifySupportContainer container>
              <Grid item xs={11}>
                <NewCheckbox
                  title='Make this assignment the lead assignment'
                  hideItalic
                  name='makeLead'
                  disabled={targets.length>1 || targets?.map((t) => t.targetType).includes(ACTION_TARGET_TYPES.PROS)}
                  value={context.makeLead as boolean}
                  onChange={(name: string, value: boolean) => onChange(name, value)}
                  description={context.makeLead ? 'If the action generates multiple assignments, only one of them will be lead.' : ''}
                />
              </Grid>
            </$NotifySupportContainer>
          }
          {isNotify &&
            <$NotifySupportContainer container>
              <Grid item xs={11}>
                <NewCheckbox
                  title='Send the email to PROs'
                  hideItalic
                  name='notifySupport'
                  value={context.notifySupport as boolean}
                  onChange={(name: string, value: boolean) => onChange(name, value)}
                />
              </Grid>
            </$NotifySupportContainer>
          }
          {isNotify &&
            <$NotifySupportContainer container>
              <Grid item xs={11}>
                <EditableMultiSelect
                  name='notifyEmails'
                  value={context.notifyEmails as string[] || []}
                  title='Send the email to these addresses (cc)'
                  placeholder='Separate tags by a comma: email1, email2...'
                  hideItalic
                  onChange={(name: string, value: string[]) => onChange(name, value)}
                />
              </Grid>
            </$NotifySupportContainer>
          }
          <$ElementContainer container>
            <Grid container item xs={11}>
              {isAssign && targets.length ?
                <>
                  <$KeepUserRolesContainer>
                    <NewCheckbox
                      title='Keep user roles'
                      hideItalic
                      name='keepUserRoles'
                      /* 
                        Disabled if there is a flow element email as target OR
                        if The target step does not have all valid assignee roles
                      */
                      disabled
                      value={!flowElementEmailTargeted}
                      onChange={(name: string, value: boolean) => onChange(name, value)}
                      description={
                        flowElementEmailTargeted ? 'Flow element email targets cannot have this enabled' : 
                          'Users must keep their roles for the selected targets'}
                    />
                  </$KeepUserRolesContainer>
                  {flowElementEmailTargeted ? <Select
                    required
                    title='Which role will be assigned to the targets?'
                    description='The users will be assigned this role for the cut-off in question. 
                    The role must be in the assignee permissions of the target step.'
                    name='roles'
                    value={roles ? roles[0] : undefined}
                    items={assignableRoles}
                    onChange={(name, value) => onChange(name, [value as string])}
                  /> : null}
                </>
                :
                <Collapse
                  bold={false}
                  fullWidth={false}
                  title='Filter by role'
                  open={open}
                  handleOpen={setOpen}
                  color={newTheme.colors.text.grey}
                >
                  <NewMultiSelect
                    name='roles'
                    items={FilteredRoleNameItems}
                    hideItalic
                    onChange={(name: string, value: string[]) => onChange(name, value)}
                    value={roles}
                    title=''
                  />
                </Collapse>
              }

            </Grid>
          </$ElementContainer>
        </>
      case 1:
        return <>
          {isNotify ?
            <AutocompleteInput
              name='notification'
              value={templateOptions.find((item) => item.value === context.notificationId)}
              items={templateOptions}
              title='Which email template will you use?'
              description="It's possible to search templates by their tags or names"
              onChange={(_: React.ChangeEvent<unknown>, val: unknown) => onChange('notification', (val as Item).value as string)}
              onInputChange={onInputChange}
              onEditItem={onEditTemplate}
            />
            :
            isVisibility ?
              <$ElementContainer container spacing={2}>
                {flowElementSelector}
                <Grid item xs={11}>
                  <NewCheckbox
                    title='Only give visibility for submitted assignments'
                    hideItalic
                    name='onlySubmitted'
                    value={context.onlySubmitted as boolean}
                    onChange={(name: string, value: boolean) => onChange(name, value)}
                  />
                </Grid>
              </$ElementContainer>
              : flowElementSelector
          }
        </>
      default:
        return <>
          <$ElementContainer container spacing={2}>
            <Grid item xs={11}>
              <Select
                name='event'
                value={event}
                items={events}
                title='What triggers this action?'
                placeholder='Select the trigger event'
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={11}>
              <Collapse
                bold={false}
                fullWidth={false}
                title='Add condition'
                open={openCondition}
                handleOpen={setOpenCondition}
                color={newTheme.colors.text.grey}
              >
                <Grid container justifyContent='center' spacing={1}>
                  {flowElementSelector}
                  <Grid item xs={6}>
                    <Select title='Condition' name='type' items={conditionTypes} value={modalState.condition?.type} onChange={onChangeCondition} />
                  </Grid>
                  <Grid item xs={6}>
                    <Input title='Compared to' disabled={modalState.condition?.type === VALIDATION_TYPES.NON_EMPTY} name='values' value={modalState.condition?.values} onChange={onChangeCondition} hideItalic />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </$ElementContainer>
        </>
    }
  }

  //TODO: Uncomment when BE has reminders
  /* const notifyButtons = () => {
    if(isNotify) {
      const hasReminder = !!modalState.reminder?.start
      return <>
        {modalStep === 2 && !reminder &&
          <NewButton variant='outlined' onClick={() => setReminder(true)}>
            {hasReminder ? 'Edit Reminder' : 'Set Reminder'}
          </NewButton>
        }
        {hasReminder && !reminder && <NewButton error onClick={deleteReminder}>Delete Reminder</NewButton>}
      </>
    } else
      return null
  } */

  return (
    <$ModalContainer>
      <>
        {content(openedEmail, reminder, event)}
        <$ActionsContainer container justifyContent='space-between'>
          <Grid item xs={3}>
            {modalStep > 0 ? <NewButton variant='outlined' onClick={() => handleBack(openedEmail)}>Back</NewButton> : <div />}
          </Grid>
          <Grid item xs container justifyContent='flex-end'>
            {/* {notifyButtons()} */}
            <NewButton disabled={isDisabled()} onClick={() => handleNext()}>{modalStep < 2 ? 'Next' : 'Save'}</NewButton>
          </Grid>
        </$ActionsContainer>
      </>
    </$ModalContainer>
  )
}
