import * as React from 'react'
import update from 'immutability-helper'
import { Title, ConfirmAction, Input, NewDatePicker, NewButton, SvgIcon, FlowCutOff, FlowStep, Tooltip } from 'prace-common-components'
import { $Button, $CutOffContainer, $CutoffName, $RemoveCutoff, $StepTitle, $TimeframeContainer } from './styles'
import Grid from '@material-ui/core/Grid'
import { EditTimeFramesProps, EditFlowCutOff } from './types'

const getEditCutoffs = (cutoffs: FlowCutOff[], steps: FlowStep[]): EditFlowCutOff[] => {
  const allTimeframes: EditFlowCutOff['timeframes'] = []
  steps.forEach((step) => {
    step.timeframes.forEach((timeframe) => {
      allTimeframes.push({
        ...timeframe,
        stepTitle: step.title,
        stepOrder: step.order,
        stepId: step.id,
      })
    })
  })
  
  return cutoffs.map((cutoff) => {
    const timeframes = allTimeframes.filter((timeframe) => timeframe.cutoffId === cutoff.id)
      .sort((a, b) => a.stepOrder - b.stepOrder)

    return {
      ...cutoff,
      timeframes,
    }
  })
}

export const EditTimeFrames: React.FC<EditTimeFramesProps> = ({ 
  cutoffs, 
  editingCall,
  steps = [], 
  callCutoffs = [], 
  onSaveCutoffs,
  onAddCutoff,
  onChangeCutOffValue,
  onDeleteCutOff,
  onTimeframeChanged,
  btnText,
  published,
}) => {
  const [stateCutoffs, setStateCutoffs] = React.useState<EditFlowCutOff[]>(
    cutoffs.length === 0 ? [{ title: '', start: null, timeframes: [] }] : getEditCutoffs(cutoffs, steps),
  )
  const hasCallCutoffs = !!callCutoffs.length
  const [openedCutoff, setOpenedCutoff] = React.useState<number>()

  const checkInvalidCutOffs = (cutoffs: EditFlowCutOff[]) => {
    let invalid = false
    cutoffs.forEach((cutOff) => {
      if(!cutOff.title || !cutOff.start)
        invalid = true
    })
    return invalid
  }

  const onChangeCutoffs = (name: string, value: StringNumber | EditFlowCutOff['timeframes'], idx: number) => {
    setStateCutoffs((stateCutoffs) => {
      /* Cutoff titles must be unique */
      if(name === 'title' && value && stateCutoffs.find((cutoff) => cutoff.title === value)) return stateCutoffs
      /* Do not allow changing the start date of this cutoff if its earlier than dates of cutoffs with higher index */
      if(name === 'start' && stateCutoffs[idx + 1] && stateCutoffs[idx + 1].start &&
        new Date(value as string).getTime() > new Date(stateCutoffs[idx + 1].start!).getTime()) {
        return stateCutoffs
      }
      const newCutoffs = update(stateCutoffs, {
        [idx]: { [name]: { $set: value }},
      })
      if(name === 'start') {
        /* If we change the cutoff opening date, all timeframes but start atleast at that date too */
        const newTimeframes = newCutoffs[idx].timeframes.map((timeframe) => ({
          ...timeframe,
          start: !timeframe.start ||
            (new Date(timeframe.start).getTime() < new Date(value as string).getTime()) ? 
            value as string : timeframe.start,
        }))
        newCutoffs[idx].timeframes = newTimeframes
      }
      onChangeCutOffValue(value as StringNumber, idx, newCutoffs[idx])
      return newCutoffs
    })
  }

  const onChangeTimeframe = (name: string, value: StringNumber, cutoffIdx: number, timeframeIdx: number) => {
    const newTimeframe = update(stateCutoffs[cutoffIdx].timeframes[timeframeIdx], {
      [name]: { $set: value || null },
    })
    const timeframesValue = update(stateCutoffs[cutoffIdx].timeframes, {
      [timeframeIdx]: { $set: newTimeframe },
    })
    if(newTimeframe.id) onTimeframeChanged(newTimeframe)
    onChangeCutoffs('timeframes', timeframesValue, cutoffIdx)
  }

  const addCutoff = () => {
    setStateCutoffs((stateCutoffs) => {
      if(checkInvalidCutOffs(stateCutoffs)) return stateCutoffs
      const newTimeframes = steps.map((step) => ({
        start: null,
        end: null,
        stepTitle: step.title,
        stepOrder: step.order,
        stepId: step.id,
      }))
      const newCutoff = { title: String(stateCutoffs.length + 1), start: null, timeframes: newTimeframes }
      const newCutoffs = [...stateCutoffs, newCutoff]
      onAddCutoff(newCutoff as unknown as FlowCutOff)
      return newCutoffs as EditFlowCutOff[]
    })
  }

  const deleteCutoff = (idx: number) => {
    setStateCutoffs((stateCutoffs) => {
      const newCutoffs = stateCutoffs.filter((_, index) => index !== idx)
      onDeleteCutOff(idx)
      return newCutoffs
    })
  }

  const handleOpenCutoff = (idx: number) => {
    setOpenedCutoff((prevOpened) => prevOpened === idx ? undefined : idx)
  }

  const onSubmitCutoffs = () => onSaveCutoffs()

  return <>
    <$CutOffContainer container>
      <Grid container spacing={2}>
        {!hasCallCutoffs && <$CutoffName editingCall={editingCall} item xs={2}>
          <Title subTitle alternate>Cut-off Name</Title>
        </$CutoffName>}
        <Grid item xs={hasCallCutoffs ? 5 : 4}>
          <Title subTitle alternate>Opening Date</Title>
        </Grid>
        <Grid item xs={hasCallCutoffs ? 5 : 4}>
          <Title subTitle alternate>Submission Deadline</Title>
        </Grid>
      </Grid>
      {stateCutoffs.map((cutoff, idx) => 
        <Grid container key={`cutoff-${cutoff?.id || ''}-${idx}`}>
          <Grid container spacing={2} alignItems='center'>
            {editingCall && <Grid item>
              <SvgIcon name={openedCutoff === idx ? 'chevron-up' : 'chevron-down'} size={24} color='#757778' clickable onClick={() => handleOpenCutoff(idx)}/>
            </Grid>}
            {!hasCallCutoffs && <Grid item xs={2}>
              <Input placeholder='Cut-off Name' title='' hideItalic name='title' value={cutoff.title} onChange={(name: string, value: Nullable<StringNumber>) => onChangeCutoffs(name, value as StringNumber, idx)} />
            </Grid>}
            <Grid item xs={hasCallCutoffs ? 5 : 4}>
              <NewDatePicker
                withTime
                placeholder='Start Cut-Off Date'
                min={idx && stateCutoffs[idx - 1].start ? new Date(stateCutoffs[idx - 1].start!) : undefined}
                max={idx !== (stateCutoffs.length - 1) && stateCutoffs[idx + 1].start ?
                  new Date(stateCutoffs[idx + 1].start!) : undefined}
                name='start'
                value={cutoff.start}
                onChange={(name: string, value: StringNumber) => onChangeCutoffs(name, value, idx)}
              />  
            </Grid>
            <Grid item xs={hasCallCutoffs ? 5 : 4}>
              <NewDatePicker
                withTime
                placeholder='Submission Deadline'
                min={cutoff.start ? new Date(cutoff.start) : undefined}
                max={idx !== (stateCutoffs.length - 1) && stateCutoffs[idx + 1].start ?
                  new Date(stateCutoffs[idx + 1].start!) : undefined}
                name='end'
                value={cutoff.timeframes.length ? cutoff.timeframes[0].end : undefined}
                disabled={!steps.length}
                /* Changing the submission deadline is euqal to changing the close date of the first step */
                onChange={(name: string, value: StringNumber) => onChangeTimeframe(name, value, idx, 0)}
              />
            </Grid>
            {!hasCallCutoffs && <$RemoveCutoff item xs={1}>
              <ConfirmAction deletion ignore={!published} title='Delete Cut-Off' description='Removing cutoffs from an ongoing call will also delete all its associated timeframes and may lead to unexpected behaviours.' onConfirm={() => deleteCutoff(idx)}>
                <SvgIcon name='delete' size={24} color='#757778' clickable onClick={() => deleteCutoff(idx)}/>
              </ConfirmAction>
            </$RemoveCutoff>}
          </Grid>
          {openedCutoff === idx && <$TimeframeContainer container>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Title subTitle alternate>Step</Title>
              </Grid>
              <Grid item xs={4}>
                <Title subTitle alternate>Step Opening</Title>
              </Grid>
              <Grid item xs={4}>
                <Title subTitle alternate>Step Deadline</Title>
              </Grid>
            </Grid>
            {cutoff.timeframes.map((timeframe, tidx) => <Grid key={`timeframe-${timeframe.id || ''}-${tidx}`} container spacing={2} alignItems='center'>
              <Grid item xs={3}>
                <Tooltip
                  placement='top'
                  show
                  text={timeframe.stepTitle}
                >
                  <$StepTitle>
                    {timeframe.stepTitle}
                  </$StepTitle>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <NewDatePicker
                  withTime
                  disabled={tidx === 0}
                  placeholder='Start Timeframe Date'
                  min={hasCallCutoffs && cutoff?.start ? new Date(cutoff.start) : undefined}
                  max={hasCallCutoffs && cutoff?.end ? new Date(cutoff.end!) : undefined}
                  name='start'
                  value={tidx === 0 ? cutoff.start : timeframe.start}
                  onChange={(name: string, value: StringNumber) => onChangeTimeframe(name, value, idx, tidx)}
                />
              </Grid>
              <Grid item xs={4}>
                <NewDatePicker
                  withTime
                  disabled={tidx === 0}
                  min={timeframe.start ? new Date(timeframe.start) : undefined}
                  max={hasCallCutoffs && cutoff?.end ? new Date(cutoff.end!) : undefined}
                  error={!!(timeframe.start && timeframe.end &&
                    (new Date(timeframe.start).getTime() > new Date(timeframe.end).getTime()))}
                  placeholder='End Timeframe Date'
                  name='end'
                  value={timeframe.end}
                  onChange={(name: string, value: StringNumber) => onChangeTimeframe(name, value, idx, tidx)}
                />
              </Grid>
            </Grid>,
            )}
          </$TimeframeContainer>
          }
        </Grid>,
      )}
      {!hasCallCutoffs && <Grid item>
        <ConfirmAction ignore={!published} title='Add Cut-Off' description='Adding cutoffs from an ongoing call lead to unexpected behaviours.' onConfirm={addCutoff}>
          <NewButton creator variant='outlined' onClick={addCutoff}>
            Add Cut-Off
          </NewButton>
        </ConfirmAction>
      </Grid>}
    </$CutOffContainer>
    <Grid container>
      <$Button onClick={onSubmitCutoffs}>
        {btnText}
      </$Button>
    </Grid>
  </>
}
