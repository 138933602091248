import { FlowCall } from 'prace-common-components'

export const validateEditCall = (call: FlowCall) => {
  const steps = call.flow || []
  /* Check if every step has assignees */
  const stepAssigneeError = steps.find((step) => !step.permissions.assignable?.length)
  if(stepAssigneeError)
    return `Step ${stepAssigneeError.title} does not have assignees`

  /* Check if every step that has assignees also has assigners (except first step) */
  const stepAssignError = steps.find(
    (step) => step.order && step.permissions.assignable?.length && !step.permissions.assign?.length)
  if(stepAssignError)
    return `Step ${stepAssignError.title} does not have assigners`

  /* Check if every step has forms */
  const stepElementsError = steps.find((step) => !step.flow?.length)
  if(stepElementsError)
    return `Step ${stepElementsError.title} does not have any form`

  /* Check if every form has elements */
  const formElementsError = steps.find((step) => !!step?.flow.find((form) => !form.flow?.length))
  if(formElementsError)
    return `Step ${formElementsError.title} has a form without elements`


  /* Check if there are  cutoffs with no start dates */
  const cutoffError = call.cutoffs.find((cutoff) => !cutoff.start)
  if(cutoffError) return 'There is a cutoff with no start date'

  /* Check if there are submission timeframes with no start and end dates */
  for (const step of steps) {
    if (step.timeframes && !step.order) {
      const timeframeError = step.timeframes.find((timeframe) => !timeframe.start || !timeframe.end)
      if (timeframeError)
        return `Step ${step.title} has a timeframe without start or end dates`
    }
  }

  return ''
}
